import BASE_URL from "../request/request.config";
export function urlEncode(param, key, encode) {
  if (param == null) return "";
  var paramStr = "";
  var t = typeof param;
  if (t == "string" || t == "number" || t == "boolean") {
    paramStr +=
      "&" +
      key +
      "=" +
      (encode == null || encode ? encodeURIComponent(param) : param);
  } else {
    for (var i in param) {
      var k =
        key == null
          ? i
          : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}

export function splitAndJoin(str) {
  var arr = str
    .trim()
    .split("\n")
    .filter((item) => item); // 使用换行符分割字符串
  var newStr = arr.join(","); // 使用逗号拼接数组
  return newStr;
}

export function exportTool(url, params = {}) {
  var token = window.localStorage.getItem("wms_token") || "";
  try {
    params.token = token;
    let data = urlEncode(params).substring(1);
    window.location.href = BASE_URL + url + "?" + data;
  } catch {
    this.$message.error("服务器繁忙，请稍后重试");
  }
}
