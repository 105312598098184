/* eslint-disable */
import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    isCollapse: false,
    visitedRoutes: [],
    adminList: ["liuhongtao", "liyushan", "lys-janson", "lys_dm", "admin"],
    excludeStr: "redirect-page,layout-view",
  },
  mutations: {
    changeCollapse(state) {
      state.isCollapse = !state.isCollapse;
    },
    addVisitedRoute(state, route) {
      let target = state.visitedRoutes.find((v) => v.path === route.path);
      if (target) {
        target = Object.assign(target, route);
      } else {
        state.visitedRoutes.push(Object.assign({}, route));
      }
    },
    delVisitedRoute(state, path) {
      state.visitedRoutes.forEach((v, i) => {
        if (v.path === path) {
          state.visitedRoutes.splice(i, 1);
        }
      });
    },
    clearVisitedRoutes(state) {
      state.visitedRoutes = [];
    },
    setExcludeStr(state, excludeStr) {
      state.excludeStr = excludeStr;
    },
  },
  getters: {
    getIsCollapse: (state) => state.isCollapse,
    getVisitedRoutes: (state) => state.visitedRoutes,
    getExcludeStr: (state) => state.excludeStr,
    getAdminList: (state) => state.adminList,
  },
});

export default store;
